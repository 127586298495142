const ACCESS_TOKEN_KEY = "accessToken"
const ACCEPT_FILE_EXT = [
  "mp4",
  "mov",
  "webm",
  "mp3",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "pdf",
  "ppt",
  "jpg",
  "jpeg",
  "svg",
  "png",
  "gif",
]
const MEDIA_TYPE = {
  VIDEO: "video",
  AUDIO: "audio",
  DOCUMENT: "document",
  IMAGE: "image",
  UNKNOWN: "unknown",
}

const MEDIA_TYPE_INDEX = {
  unknown: 0,
  audio: 1,
  video: 2,
  image: 3,
  document: 4,
}

const MEDIA_TYPES = [
  { text: "Unknown", value: 0 },
  { text: "Audio", value: 1 },
  { text: "Video", value: 2 },
  { text: "Image", value: 3 },
  { text: "Document", value: 4 },
]

const EXT_TO_MEDIA_TYPE = {
  "": MEDIA_TYPE.UNKNOWN,
  mp4: MEDIA_TYPE.VIDEO,
  mov: MEDIA_TYPE.VIDEO,
  webm: MEDIA_TYPE.VIDEO,
  mp3: MEDIA_TYPE.AUDIO,
  doc: MEDIA_TYPE.DOCUMENT,
  docx: MEDIA_TYPE.DOCUMENT,
  xls: MEDIA_TYPE.DOCUMENT,
  xlsx: MEDIA_TYPE.DOCUMENT,
  pdf: MEDIA_TYPE.DOCUMENT,
  ppt: MEDIA_TYPE.DOCUMENT,
  jpg: MEDIA_TYPE.IMAGE,
  jpeg: MEDIA_TYPE.IMAGE,
  svg: MEDIA_TYPE.IMAGE,
  png: MEDIA_TYPE.IMAGE,
  gif: MEDIA_TYPE.IMAGE,
}

const EXT_TO_MEDIA_TYPE_COLOR = {
  doc: "#ef6950",
  docx: "#ef6950",
  xls: "#e3008b",
  xlsx: "#e3008b",
  pdf: "#ff0000",
  ppt: "#ff0000",
}

const PrimeType = {
  Quote: { text: "Quote", value: 1 },
  Audio: { text: "Audio", value: 2 },
  Video: { text: "Video", value: 3 },
}

const PrimeTypeIndex = {
  1: "Quote",
  2: "Audio",
  3: "Video",
}

//================================
// SUBSCRIPTION
//================================
const DURATION_UNIT_OBJECT = {
  Month: { text: "Month", value: 1 },
  Year: { text: "Year", value: 2 },
}

const DURATION_UNIT_LIST = [
  { text: "Month", value: 1 },
  { text: "Year", value: 2 },
]

const SUBSCRIPTION_TYPE_OBJECT = {
  OneTime: { text: "One Time", value: 1 },
  AutoRenew: { text: "Auto Renew", value: 2 },
}

const SUBSCRIPTION_TYPE_LIST = [
  { text: "One Time", value: 1 },
  { text: "Auto Renew", value: 2 },
]

const SUBSCRIPTION_PRODUCT = {
  "com.innercise.yearly3": "Annual Subscription",
  "com.innercise.yearly": "Annual Subscription",
  "com.innercise.monthly": "Monthly Subscription",
  "com.innercise.monthly3": "Monthly Subscription",
  "com.neurogym.lifetime": "Lifetime Plan",
}

const CURRENCY_LIST = ["usd", "cad", "eur", "aud"]

const PRODUCT_PLATFORM = [
  { text: "Apple Pay", value: 1 },
  { text: "Stripe", value: 2 },
  { text: "Google Pay", value: 3 },
]

const MODAL_PRESENTATION_TYPE = [
  { text: "Popup", value: 1 },
  { text: "Modal", value: 2 },
]

const MODAL_CONTENT_TYPE = [
  { text: "Audio", value: 1 },
  { text: "Video", value: 2 },
  { text: "Image", value: 3 },
  { text: "Text Title", value: 4 },
  { text: "Text Description", value: 5 },
  { text: "Button Main", value: 6 },
  { text: "Button Other", value: 7 },
]

const MODAL_CONTENT_TYPE_INDEX = {
  0: "Unknown",
  1: "Audio",
  2: "Video",
  3: "Image",
  4: "Text Title",
  5: "Text Description",
  6: "Button Main",
  7: "Button Other",
}

const ORDER_STATUS = {
  0: "Created",
  1: "Success",
  2: "Fail",
  3: "Refunded",
}

const PAYMENT_TYPE = {
  0: "N/A",
  1: "Apple",
  2: "Redeem",
  3: "Google",
  4: "Stripe",
  5: "Infusion Soft",
}

const PAYMENT_TYPE_INDEX = {
  NONE: 0,
  APPLE: 1,
  REDEEM: 2,
  GOOGLE: 3,
  STRIPE: 4,
  INFUSION_SOFT: 5,
}

const CHANNEL_TYPES = {
  1: { value: 1, text: "Male Voice" },
  2: { value: 2, text: "Female Voice" },
}

const MEMBER_STATUS = {
  0: { value: 0, text: "deactivate" },
  1: { value: 1, text: "activate" },
}
const MEMBER_STATUS_INDEX = {
  DEACTIVATE: 0,
  ACTIVATE: 1,
}

const SYSTEM_SETTINGS = {
  1: { value: 1, text: "Text" },
  2: { value: 2, text: "HTML" },
  3: { value: 3, text: "Image" },
  4: { value: 4, text: "Video" },
  5: { value: 5, text: "Number" },
  6: { value: 6, text: "Audio" },
  7: { value: 7, text: "Link" },
  8: { value: 8, text: "Attachment" },
  9: { value: 9, text: "Media" },
  10: { value: 9, text: "Color" },
}

const SYSTEM_SETTINGS_INDEX = {
  TEXT: 1,
  HTML: 2,
  IMAGE: 3,
  VIDEO: 4,
  NUMBER: 5,
  AUDIO: 6,
  LINK: 7,
  ATTACHMENT: 8,
  MEDIA: 9,
  COLOR: 10,
}

const PROGRAM_TYPE = {
  1: { value: 1, text: "Multiple" },
  2: { value: 2, text: "Single" },
}

const PROGRAM_TYPE_INDEX = {
  MULTIPLE: 1,
  SINGLE: 2,
}

const PROGRAM_PUB_STATUS = {
  0: { value: 0, text: "Unpublished" },
  1: { value: 1, text: "Published" },
}

const TRIAL_STATUS = {
  0: { value: 0, text: "InActivate" },
  1: { value: 1, text: "Activate" },
  2: { value: 2, text: "Expired" },
}

const TRIAL_STATUS_INDEX = {
  INACTIVATE: 0,
  ACTIVATE: 1,
  EXPIRED: 2,
}

const REDEEM_CODE_TYPE = [
  { value: 1, text: "Redeem Code" },
  { value: 2, text: "Offer Code" },
]
const REDEEM_CODE_TYPE_DICT = {
  1: { value: 1, text: "Redeem Code" },
  2: { value: 2, text: "Offer Code" },
}

const REDEEM_CODE_TYPE_INDEX = {
  REDEEM_CODE: 1,
  OFFER_CODE: 2,
}

const ANALYTIC_SUBSCRIBE_TYPE = {
  MONTHLY: 1,
  YEARLY: 2,
  LIFETIME: 3,
}

const START_RESOURCE_DATA_TYPE = {
  INNERCISE: 1,
}

const RAIL_GROUP_TYPE = {
  REGULAR: { value: 1, text: "Regular" },
  RULE: { value: 2, text: "Rule" },
}

const RAIL_GROUP_TYPE_INDEX = {
  1: "Regular",
  2: "Rule",
}

const RAIL_GROUP_STATUS = {
  ACTIVE: { value: 1, text: "Active" },
  DELETED: { value: 2, text: "Deleted" },
}

const RAIL_GROUP_STATUS_INDEX = {
  1: "Active",
  2: "Deleted",
}

const RAIL_TYPE = {
  CUSTOMIZED: { value: 0, text: "Customized" },
  CONTINUE_LISTENING: { value: 1, text: "Continue Listening" },
  START_HERE: { value: 2, text: "Start Here" },
  RECOMMEND: { value: 3, text: "Recommend" },
  RECENTLY_ADDED: { value: 4, text: "Recently Added" },
  FEATURED_PROGRAM: { value: 5, text: "Featured Program" },
}

const RAIL_TYPE_INDEX = {
  0: "Customized",
  1: "Continue Listening",
  2: "Start Here",
  3: "Recommend",
  4: "Recently Added",
  5: "Featured Program",
}

const RAIL_UI_TYPE = {
  FULL_SCREEN: { value: 1, text: "Full Screen" },
  REGULAR: { value: 2, text: "Regular" },
  SMALL: { value: 3, text: "Small" },
}

const RAIL_UI_TYPE_INDEX = {
  1: "Full Screen",
  2: "Regular",
  3: "Small",
}

const RAIL_RESOURCE_TYPE = {
  PROGRAM: { value: 1, text: "Program" },
  INNERCISE: { value: 2, text: "Innercise" },
  VIDEO_RESOURCE: { value: 3, text: "Video Resource" },
  AUDIO_RESOURCE: { value: 4, text: "Audio Resource" },
  IMAGE_RESOURCE: { value: 5, text: "Image Resource" },
}

const RAIL_TAG = {
  CUSTOMIZED: { value: 0, text: "Customized" },
  CONTINUE_LISTENING: { value: 1, text: "Continue Listening" },
  START_HERE: { value: 2, text: "Start Here" },
  RECOMMEND: { value: 3, text: "Recommend" },
  RECENTLY_ADDED: { value: 4, text: "Recently Added" },
  FEATURED_PROGRAM: { value: 5, text: "Featured Program" },
}

const RAIL_TAG_INDEX = {
  0: "Customized",
  1: "Continue Listening",
  2: "Start Here",
  3: "Recommend",
  4: "Recently Added",
  5: "Featured Program",
}

const REGISTER_METHOD = {
  1: "Manual",
  2: "FaceBook",
  3: "Google",
  4: "Apple",
  5: "Twitter",
}

const SUBSCRIPTION_STATUS = {
  INACTIVATE: 0,
  ACTIVATE: 1,
}

const INNERCISE_MODE = {
  INNERCISE: { text: "Innercise", value: 1 },
  PLAYBOOK: { text: "Playbook", value: 2 },
}

const PLAYBOOK_RESPONSE_QUESTION_TYPE_DICT = [
  { TEXT: "Short Text", VALUE: 0 },
  { TEXT: "Long Text", VALUE: 1 },
  { TEXT: "Dropdown", VALUE: 2 },
  { TEXT: "Checkbox", VALUE: 3 },
]

const PLAYBOOK_RESPONSE_QUESTION_TYPE = {
  SHORT_TEXT: 0,
  LONG_TEXT: 1,
  DROPDOWN: 2,
  CHECKBOX: 3,
}
const PLAYBOOK_RESPONSE_QUESTION_TYPE_INDEX = {
  0: "Short Text",
  1: "Long Text",
  2: "Dropdown",
  3: "Checkbox",
}
const REVIEW_TYPE_INDEX = {
  INNERCISE: 1,
  ONBOARD: 2,
  PROGRAM: 3,
}

const REVIEW_TYPE = {
  1: "Innercise",
  2: "Onboard",
  3: "Program",
}

export {
  ACCESS_TOKEN_KEY,
  ACCEPT_FILE_EXT,
  EXT_TO_MEDIA_TYPE,
  MEDIA_TYPE_INDEX,
  MEDIA_TYPE,
  MEDIA_TYPES,
  EXT_TO_MEDIA_TYPE_COLOR,
  PrimeType,
  PrimeTypeIndex,
  DURATION_UNIT_OBJECT,
  DURATION_UNIT_LIST,
  SUBSCRIPTION_TYPE_OBJECT,
  SUBSCRIPTION_TYPE_LIST,
  SUBSCRIPTION_PRODUCT,
  CURRENCY_LIST,
  PRODUCT_PLATFORM,
  MODAL_PRESENTATION_TYPE,
  MODAL_CONTENT_TYPE,
  MODAL_CONTENT_TYPE_INDEX,
  ORDER_STATUS,
  PAYMENT_TYPE,
  PAYMENT_TYPE_INDEX,
  CHANNEL_TYPES,
  MEMBER_STATUS,
  MEMBER_STATUS_INDEX,
  SYSTEM_SETTINGS,
  SYSTEM_SETTINGS_INDEX,
  PROGRAM_TYPE,
  PROGRAM_TYPE_INDEX,
  PROGRAM_PUB_STATUS,
  TRIAL_STATUS,
  TRIAL_STATUS_INDEX,
  REDEEM_CODE_TYPE,
  REDEEM_CODE_TYPE_DICT,
  REDEEM_CODE_TYPE_INDEX,
  START_RESOURCE_DATA_TYPE,
  ANALYTIC_SUBSCRIBE_TYPE,
  RAIL_GROUP_TYPE,
  RAIL_GROUP_TYPE_INDEX,
  RAIL_GROUP_STATUS,
  RAIL_GROUP_STATUS_INDEX,
  RAIL_TYPE,
  RAIL_TYPE_INDEX,
  RAIL_TAG,
  RAIL_TAG_INDEX,
  RAIL_UI_TYPE,
  RAIL_UI_TYPE_INDEX,
  RAIL_RESOURCE_TYPE,
  REGISTER_METHOD,
  SUBSCRIPTION_STATUS,
  INNERCISE_MODE,
  PLAYBOOK_RESPONSE_QUESTION_TYPE,
  PLAYBOOK_RESPONSE_QUESTION_TYPE_DICT,
  PLAYBOOK_RESPONSE_QUESTION_TYPE_INDEX,
  REVIEW_TYPE_INDEX,
  REVIEW_TYPE,
}
